<template>
    <div>
        <div
                class="form-group mt-2"
                v-for="signer in items.iten.signers"
                v-bind:key="signer.id">
            <ul>
                <li>

                    <div v-if="signer.signed_at === null && items?.iten?.status === 'running'" class="whats pointer mt-3">
                        <div v-if="signer.signed_at === null"
                             class="whats pointer mr-4 btn-primary">
                            <a
                                    :data-flow="'left'"
                                    :data-tooltip="'Copiar Link de Solicitação de Assinatura'"
                                    @click="copyToClipboard(signer.url_signature)">
                                <i class="icon-copy" style="color: #0a3a75"></i>
                            </a>
                        </div>
                        <div style="inset-inline: auto"
                             class="ml-4">
                            <a :data-flow="'left'"
                               :data-tooltip="'Solicitação de assinatura via whatsapp'"
                               @click="sendWhatsapp(signer)">
                                <img src="/img/whatsapp.png">
                            </a>
                        </div>
                    </div>
                    <div
                            class="list-primary hide-text"
                            style="display: inline-block">
                        <div :data-tooltip=stats(signer.status_of_signature) data-flow="bottom">
                            <div
                                    id="statusSigner"
                                    :class="selectClass(signer.status_of_signature)"></div>
                        </div>
                        <div
                                class="name-signer">{{ signer.name }}
                        </div>
                        <div
                                class="seller"
                                id="sing_as"> {{ stats(signer.sign_as) }}
                        </div>
                    </div>
                    <div
                            v-if="signer.signed_at !== null"
                            class="date">
                        <label>{{ t("DOCUMENTS.DATE_OF_ASSYGN") }}:</label>
                        <div>{{ formatDate(signer.signed_at) }}</div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';
import documentMixin from '../../../mixins/documentMixin';

export default {
    name: "detailsItensComponent",
    props: ['items'],
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    mixins: [documentMixin],
}
</script>

<style scoped>

</style>