<script>
import moment from 'moment';
import Helper from '@/Helpers/helper';
import Clipboard from 'clipboard';
import {mapActions} from 'vuex';
export default {
    name: "documentMixin",
    methods: {
        ...mapActions({
            showDocument: 'showDocument',
            showDocumentSigned: 'showDocumentSigned',
            sendWhatsappMessage: 'sendWhatsappMessage',
            actionCancelDocument: 'actionCancelDocument',
        }),
        getSignersNotSigned(item) {
            let signers = []
            item.signers?.forEach(i => {
                if (i.signed_at === null) {
                    signers.push(i);
                }
            })
            if (signers?.length) return signers;
            return null;
        },
        async notifyAllPendin(item) {
            await this.$swal.fire({
                title: this.t('DOCUMENTS.REALLY_NOTIFY_PENDING'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let signers = this.getSignersNotSigned(item)
                    if (signers.length) {
                        this.$store.commit('changeLoading', true);
                        for (const signer of signers) {
                            await this.sendWhatsappMessage({whatsapp: signer, showLoading: false});
                        }
                        this.$store.commit('changeLoading', false);
                    }
                }
            });
        },
        quantityOfSignatures(item) {
            return Helper.signaturesQuantity(item);
        },
        stats(word) {
            return Helper.translate(word);
        },
        selectClass(status) {
            return Helper.selectClass(status);
        },
        open(client) {
            client.open = !client.open ? true : false;
        },
        formatDate(date) {
            if (date) {
                return moment(String(date)).format('DD/MM/YYYY H:mm')
            }
            return "";
        },
        copyToClipboard(text) {
            const clipboard = new Clipboard('.btn-primary', {
                text: function () {
                    return text;
                }
            });
            clipboard.on('success', (e) => {
                this.toast.success('Link copiado com sucesso!');
                clipboard.destroy();
                e.clearSelection();
            });
            clipboard.onClick({currentTarget: document.querySelector('.btn-primary')});
        },
        sendWhatsapp(signer) {
            signer.company_name = localStorage.getItem('company_name');
            this.$swal.fire({
                title: this.t('DOCUMENTS.MSG.ARE_SURE_WANT_TO_SEND_WHATSAPP_MESSAGE'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.sendWhatsappMessage({whatsapp: signer})
                }
            });
        },
        showAlert(id, accessToken) {
            this.$swal.fire({
                title: 'Realmente deseja cancelar este documento?',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Prosseguir`,
                cancelButtonText: 'Voltar'
            }).then((result) => {
                if (result.isConfirmed) {
                    return this.actionCancelDocument({id, accessToken});
                }
            });
        },
    }
}
</script>

<style scoped>

</style>