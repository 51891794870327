<template>
    <div>
        <div class="modal-body">
            <div class="row gutters">
                <div class="form-group col-12">
                    <label for="name_document">
                        {{ t('DOCUMENTS.DOCUMENT_NAME') }}:
                    </label>
                    <input
                            type="text"
                            id="name_document"
                            class="form-control"
                            placeholder="Nome do Documento"
                            v-model="filter.name_document">
                </div>
                <div
                        class="form-group col-6">
                    <label for="signer">
                        {{ t("DOCUMENTS.SIGNER") }}:
                    </label>
                    <input type="text"
                           id="signer" class="form-control"
                           placeholder="Signatário"
                           v-model="filter.signer">
                </div>
                <div class="form-group col-6">
                    <label for="signer">
                        {{ t("DOCUMENTS.EMAIL_SIGNER") }}:
                    </label>
                    <input type="text"
                           id="email" class="form-control"
                           placeholder="email"
                           v-model="filter.email">
                </div>
                <div class="form-group col-6">
                    <label for="date_expiration">
                        {{ t("DOCUMENTS.DATE_EXPIRATION_INITIAL") }}:
                    </label>
                    <input
                            type="date"
                            id="date_expiration" class="form-control"
                            placeholder="date_expiration"
                            v-model="filter.expiration_date">
                </div>
                <div class="form-group col-6">
                    <label for="date_expiration_end">
                        {{ t("DOCUMENTS.DATE_EXPIRATION_FINAL") }}:
                    </label>
                    <input type="date"
                           id="date_expiration_end" class="form-control"
                           placeholder="date_expiration_end"
                           v-model="filter.expiration_date_end">
                </div>
                <div class="form-group col-6">
                    <label>
                        {{ t("DOCUMENTS.STATUS") }}:
                    </label>
                    <select2
                            :settings="{width: '100%'}"
                            :options="[
                            {id:'running', text: 'Aguardando'},
                            {id:'signed', text: 'Assinados'},
                            {id:'canceled', text: 'Cancelados'},
                            {id:'expired', text: 'Expirados'},
                            {id:'all', text: 'Todos'},
                            ]"
                            @select="selectStatus"
                            :disabled="$route.name !== 'All'"
                            v-model="filter.status"
                            placeholder="Selecione"
                    ></select2>
                </div>
                <div class="form-group col-6" v-if="$route.name !== 'PendingAdm'">
                    <label>{{ t("DOCUMENTS.PENDING_BY") }}:</label>
                    <select2 :settings="{width: '100%'}"
                             :options="[
                            {id:'buyer', text: 'Compradores'},
                            {id:'broker', text: 'Corretores'},
                            {id:'seller', text: 'Vendedores'},
                            {id:'witness', text: 'Testemunhas'},
                            ]"
                             :disabled="(filter.status !== 'running' && filter.status !== 'all')"
                             v-model="filter.pendingBy"
                             placeholder="Selecione"
                    ></select2>
                </div>
                <div class="form-group col-6" v-if="$route.name === 'PendingAdm'">
                    <label>Pendentes somente por:</label>
                    <input class="form-control" type="text" placeholder="Administração" disabled>
                </div>
            </div>
        </div>
        <div
                class="modal-footer">
            <button
                    class="btn-secondary btn"
                    @click="clearFilter()">
                {{ t("ACTIONS.TRASH") }}
            </button>
            <button
                    class="btn-primary btn"
                    @click="filterEmit">
                {{ t("ACTIONS.FILTER") }}
            </button>
        </div>
    </div>
</template>

<script>
import Select2 from 'vue3-select2-component';
import {useI18n} from 'vue-i18n';
import {useToast} from 'vue-toastification';

export default {
    name: "filterComponent",
    data() {
        return {
            filter: {},
        }
    },
    setup() {
        const {t} = useI18n();
        const toast = useToast();
        return {t, toast}
    },
    components: {
        Select2,
    },
    props: {
        statusIndex: {
            type: String,
        }
    },
    mounted() {
        this.filter.status = this.statusIndex;
    },
    watch: {
        statusIndex() {
            this.filter.status = this.statusIndex;
        }
    },
    methods: {
        filterEmit() {
            if (this.filter.status !== 'running' && this.filter.status !== 'all') {
                delete this.filter.pendingBy;
            }
            this.$emit('filtering', this.filter)
        },
        selectStatus({id}) {
            if (id !== 'running' && id !== 'all') {
                delete this.filter.pendingBy;
            }
        },
        clearFilter() {
            this.filter = {};
            this.$emit('clearFilter');
        }
    }
}
</script>

<style scoped>

</style>